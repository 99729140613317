<template>
    <div v-if="trashCounter" class=" anime transform top-0 left-0 w-full h-full fixed "  :class="trashCounter ? 'translate-x-0' : '-translate-x-full'">
        <div class="container mx-auto bg-white rounded  w-10/12 sm:w-6/12 mt-12">
         <span class="flex justify-end p-2 pb-1"><Iconclose @click="passTrashCounter"/></span>
            <div class="p-4 pt-0">
                <div class="flex justify-between items-center">
                    <span class="hidden sm:flex text-2xl font-semibold" style="color: #201F1E;">{{ $t("meterTrash.title") }} ?</span>
                    <span class="sm:hidden text-xl font-semibold" style="color: #201F1E;">{{ $t("meterTrash.title") }}</span>
                </div>

                <div class="py-4">
                    <span>
                        <span class="hidden sm:flex text-base">{{ $t("meterTrash.subtitle") }}</span>
                        <span class="sm:hidden text-sm font-semibold" v-html="$t('meterTrash.subtitlemb')"></span>
                    </span>

                    <span class="flex items-center mt-4">
                        <button class=" text-white border h-search sm:h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 px-4 sm:px-6 text-sm sm:text-base rounded focus:outline-none" @click="deleteCounter">{{ $t("meterDash.trash") }}</button>
                        <button class="ml-4 border text-kbutton-1 border-kbutton-1 h-search sm:h-minput  hover:border-kbutton-2 hover:text-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 px-4 sm:px-6 text-sm sm:text-base rounded focus:outline-none" @click="passTrashCounter">{{ $t("homeDash.cancelBtn") }}</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Iconclose from '../../assets/svg/dashbIcons/close.svg?inline';
import {mapGetters} from 'vuex'
export default {
    props: [
        "isPost"
    ],
    components:{
        Iconclose
    },
    computed: {
        ...mapGetters({
            trashCounter: 'getTrashCounter',

        }),
        
    },
    data() {
        return {
        }
    },
    
    methods: {
        passTrashCounter(){
            this.$store.commit('mutTrashCounter', false);
        },
        deleteCounter(){
            if (this.isPost) {
                this.$emit('confirm');
                this.passTrashCounter();
                return;
            }
            this.$store.commit('mutCountersUpdate');
            this.passTrashCounter();
        },

    },
    watch: {
    trashCounter: {
      immediate: true,
      handler(trashCounter) {
       if (process.client) {
          if (trashCounter) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      }
    }
    }
}
</script>

<style scoped>
.anime{
    animation: modal 400ms ease-out forwards;
}
@keyframes modal {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    25% {
        background-color: rgba(0, 0, 0, 0.15);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.3);
    }
    75% {
        background-color: rgba(0, 0, 0, 0.5);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.75);
    }
}
</style>