<template>
  <div class="bg-lg h-full ffamily-pro">
    <div class="p-5 sm:p-10">
      <tabs
        :items="[
          {
            key: 'post',
            title: $t('counterRecharge.counter_post'),
            active: true,
          },
          {
            key: 'pre',
            title: $t('counterRecharge.counter_pre'),
            active: false,
          },
        ]"
      >
        <template v-slot:pre>
          <div class="mt-4">
            <div
              class="
                mb-6
                flex
                lg:flex-row
                flex-col
                justify-between
                font-semibold
              "
            >
              <span class="text-tex-1 text-xl">{{
                $t("meterDash.title", { count: counters.length })
              }}</span>
              <span
                class="
                  lg:hidden
                  h-search
                  flex
                  mb-4
                  items-center
                  border
                  py-1
                  border-kborder-900
                  bg-kborder-300 bg-opacity-50
                  rounded
                "
              >
                <span class="px-3"><Iconsearch /></span>
                <input
                  :placeholder="$t('meterDash.search')"
                  class="w-full text-sm bg-transparent focus:outline-none"
                  type="text"
                  id="myInputlg"
                  v-model="searchInput"
                  @input="searchFunctionlg"
                />
              </span>
              <button
                class="
                  text-white text-sm
                  bg-radio-100
                  hover:bg-compteur-600
                  rounded
                  px-10
                  py-2
                  focus:outline-none
                "
                @click="passAddCounter"
              >
                {{ $t("meterDash.addBtn") }}
              </button>
              <button
                @click="deleteMass"
                v-show="deletecheck.length > 0"
                class="
                  flex
                  items-center
                  justify-center
                  lg:hidden
                  mt-3
                  text-white text-sm
                  bg-kinput-4
                  hover:bg-tex-2
                  rounded
                  px-10
                  py-2
                  focus:outline-none
                "
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7342 3.375H11.8125V1.6875C11.8125 1.53832 11.7532 1.39524 11.6477 1.28975C11.5423 1.18426 11.3992 1.125 11.25 1.125H6.75C6.60082 1.125 6.45774 1.18426 6.35225 1.28975C6.24676 1.39524 6.1875 1.53832 6.1875 1.6875V3.375H2.26582L2.25 4.78125H3.41016L4.11645 15.8203C4.13432 16.1058 4.26026 16.3737 4.46866 16.5696C4.67705 16.7656 4.95225 16.8748 5.23828 16.875H12.7617C13.0476 16.875 13.3227 16.7661 13.5312 16.5705C13.7397 16.375 13.8659 16.1074 13.8843 15.8221L14.5898 4.78125H15.75L15.7342 3.375ZM6.1875 14.625L5.87109 5.625H7.03125L7.34766 14.625H6.1875ZM9.5625 14.625H8.4375V5.625H9.5625V14.625ZM10.4062 3.375H7.59375V2.39063C7.59375 2.35333 7.60857 2.31756 7.63494 2.29119C7.66131 2.26482 7.69708 2.25 7.73438 2.25H10.2656C10.3029 2.25 10.3387 2.26482 10.3651 2.29119C10.3914 2.31756 10.4062 2.35333 10.4062 2.39063V3.375ZM11.8125 14.625H10.6523L10.9688 5.625H12.1289L11.8125 14.625Z"
                    fill="white"
                  />
                </svg>
                <span class="ml-2"
                  >{{ $t("meterDash.trash") }} ({{ deletecheck.length }})</span
                >
              </button>
            </div>
            <div class="hidden lg:flex items-center mb-4">
              <button
                @click="deleteMass"
                v-show="deletecheck.length > 0"
                class="
                  mr-4
                  flex
                  items-center
                  justify-center
                  text-white text-sm
                  bg-kinput-4
                  hover:bg-tex-2
                  rounded
                  px-4
                  h-search
                  focus:outline-none
                "
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7342 3.375H11.8125V1.6875C11.8125 1.53832 11.7532 1.39524 11.6477 1.28975C11.5423 1.18426 11.3992 1.125 11.25 1.125H6.75C6.60082 1.125 6.45774 1.18426 6.35225 1.28975C6.24676 1.39524 6.1875 1.53832 6.1875 1.6875V3.375H2.26582L2.25 4.78125H3.41016L4.11645 15.8203C4.13432 16.1058 4.26026 16.3737 4.46866 16.5696C4.67705 16.7656 4.95225 16.8748 5.23828 16.875H12.7617C13.0476 16.875 13.3227 16.7661 13.5312 16.5705C13.7397 16.375 13.8659 16.1074 13.8843 15.8221L14.5898 4.78125H15.75L15.7342 3.375ZM6.1875 14.625L5.87109 5.625H7.03125L7.34766 14.625H6.1875ZM9.5625 14.625H8.4375V5.625H9.5625V14.625ZM10.4062 3.375H7.59375V2.39063C7.59375 2.35333 7.60857 2.31756 7.63494 2.29119C7.66131 2.26482 7.69708 2.25 7.73438 2.25H10.2656C10.3029 2.25 10.3387 2.26482 10.3651 2.29119C10.3914 2.31756 10.4062 2.35333 10.4062 2.39063V3.375ZM11.8125 14.625H10.6523L10.9688 5.625H12.1289L11.8125 14.625Z"
                    fill="white"
                  />
                </svg>
                <span class="ml-2"
                  >{{ $t("meterDash.trash") }} ({{ deletecheck.length }})</span
                >
              </button>
              <div
                class="
                  hidden
                  lg:flex
                  w-1/4
                  items-center
                  border-1.5
                  h-search
                  py-1
                  border-kborder-900
                  bg-kborder-300 bg-opacity-50
                  rounded
                "
              >
                <span class="p-3"><Iconsearch /></span>
                <input
                  :placeholder="$t('meterDash.search')"
                  class="w-full text-sm bg-transparent focus:outline-none"
                  type="text"
                  id="myInput"
                  v-model="searchInput"
                  @input="searchFunction"
                />
              </div>
            </div>
            <div v-if="counters.length != 0" class="bg-white">
              <table
                class="
                  w-full
                  table-auto
                  xl:table-fixed
                  text-sm
                  border border-solid
                "
              >
                <thead
                  class="
                    hidden
                    lg:table-header-group
                    font-bold
                    text-left
                    border border-bgk-2 border-solid
                  "
                >
                  <th class="py-3 px-5 w-16">
                    <input
                      type="checkbox"
                      class="form-checkbox h-5 w-5 text-radio-100"
                      id="all"
                      value="allcounters"
                      v-model="alldelete"
                      @click="checkAll"
                    />
                  </th>
                  <th>{{ $t("meterDash.counterNumb") }}</th>
                  <th>{{ $t("meterDash.owner") }}</th>
                  <th>{{ $t("meterDash.family") }}</th>
                  <th>{{ $t("homeDash.action") }}</th>
                </thead>
                <tbody v-if="!searchInput">
                  <tr
                    class="hover:bg-bgk-1 border border-bgk-2 border-solid"
                    v-for="counter in counters"
                    :key="counter.id"
                  >
                    <td class="py-3 px-5 w-16">
                      <input
                        type="checkbox"
                        class="form-checkbox h-5 w-5 text-radio-100"
                        :id="counter.id"
                        :value="counter.id"
                        v-model="deletecheck"
                        @change="updateCheckall"
                      />
                    </td>
                    <td class="flex flex-col p-3">
                      <span class="text-sm">{{ counter.cNumb }}</span>
                      <span class="text-xs lg:hidden" style="color: #4d4c4c">{{
                        counter.cUser
                      }}</span>
                    </td>
                    <td>
                      <span class="hidden lg:table-cell">{{
                        counter.cUser
                      }}</span>
                    </td>
                    <td class="flex flex-col p-3">
                      <span class="text-sm lg:hidden">{{
                        $t("meterDash.family")
                      }}</span>
                      <span class="text-xs text-tex-3 lg:text-black">{{
                        counter.cFamily
                      }}</span>
                    </td>
                    <td class="px-2 lg:px-0 w-8 lg:w-auto">
                      <div class="lg:flex hidden items-center">
                        <button
                          class="focus:outline-none mr-2"
                          :title="$t('tooltip.seebill')"
                        >
                          <Iconseen
                            @click="
                              seeMeterBills(counter.cNumb, counter.createdDate)
                            "
                          />
                        </button>
                        <button
                          class="focus:outline-none mr-2"
                          :title="$t('tooltip.editbill')"
                          @click="editMeter(counter.cNumb, counter.id)"
                        >
                          <Iconedit />
                        </button>
                        <button
                          class="focus:outline-none mr-2"
                          :title="$t('tooltip.trashbill')"
                          @click="passTrashCounter(counter.id)"
                        >
                          <Icontrash />
                        </button>
                        <div class="border-l-2">
                          <button
                            class="focus:outline-none flex items-center ml-2"
                            @click="reload(counter.cNumb, counter.cUser)"
                          >
                            <Iconreload />
                            <span
                              class="text-sm hidden lg:block font-semibold ml-2"
                              style="color: #0965f6"
                              >{{ $t("meterDash.recharge") }}</span
                            >
                          </button>
                        </div>
                      </div>

                      <div class="lg:hidden">
                        <Icondatatabl
                          @click="counter.active = !counter.active"
                          class=""
                        />
                        <div
                          v-show="counter.active == true"
                          @click="counter.active = !counter.active"
                          class="
                            absolute
                            -ml-24
                            bg-white
                            flex flex-col
                            p-4
                            shadow-xs
                            rounded
                          "
                        >
                          <button
                            class="focus:outline-none flex items-center mb-1"
                          >
                            <Iconseen
                              @click="
                                seeMeterBills(
                                  counter.cNumb,
                                  counter.createdDate
                                )
                              "
                              class="mr-3"
                            /><span>{{ $t("meterDash.see") }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            @click="editMeter(counter.cNumb, counter.id)"
                          >
                            <Iconedit class="mr-3" /><span>{{
                              $t("meterDash.edit")
                            }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            @click="passTrashCounter(counter.id)"
                          >
                            <Icontrash class="mr-3" /><span>{{
                              $t("meterDash.trash")
                            }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            @click="reload(counter.cNumb, counter.cUser)"
                          >
                            <Iconreload class="mr-3" /><span>{{
                              $t("meterDash.recharge")
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="searchInput">
                  <tr
                    class="hover:bg-bgk-1 border border-bgk-2 border-solid"
                    v-for="counter in searchCounters"
                    :key="counter.id"
                  >
                    <td class="py-3 px-5 w-16">
                      <input
                        type="checkbox"
                        class="form-checkbox h-5 w-5 text-radio-100"
                        :id="counter.id"
                        :value="counter.id"
                        v-model="deletecheck"
                        @change="updateCheckall"
                      />
                    </td>
                    <td class="flex flex-col p-3">
                      <span class="text-sm">{{ counter.cNumb }}</span>
                      <span class="text-xs lg:hidden" style="color: #4d4c4c">{{
                        counter.cUser
                      }}</span>
                    </td>
                    <td>
                      <span class="hidden lg:table-cell">{{
                        counter.cUser
                      }}</span>
                    </td>
                    <td class="flex flex-col p-3">
                      <span class="text-sm lg:hidden">{{
                        $t("meterDash.family")
                      }}</span>
                      <span class="text-xs text-tex-3 lg:text-black">{{
                        counter.cFamily
                      }}</span>
                    </td>
                    <td class="px-2 lg:px-0 w-8 lg:w-auto">
                      <div class="lg:flex hidden items-center">
                        <button class="focus:outline-none mr-2">
                          <Iconseen
                            @click="
                              seeMeterBills(counter.cNumb, counter.createdDate)
                            "
                          />
                        </button>
                        <button
                          class="focus:outline-none mr-2"
                          @click="editMeter(counter.cNumb, counter.id)"
                        >
                          <Iconedit />
                        </button>
                        <button
                          class="focus:outline-none mr-2"
                          @click="passTrashCounter(counter.id)"
                        >
                          <Icontrash />
                        </button>
                        <div class="border-l-2">
                          <button
                            class="focus:outline-none flex items-center ml-2"
                            @click="reload(counter.cNumb, counter.cUser)"
                          >
                            <Iconreload />
                            <span
                              class="text-sm hidden lg:block font-semibold ml-2"
                              style="color: #0965f6"
                              >{{ $t("meterDash.recharge") }}</span
                            >
                          </button>
                        </div>
                      </div>

                      <div class="lg:hidden">
                        <Icondatatabl
                          @click="counter.active = !counter.active"
                          class=""
                        />
                        <div
                          v-show="counter.active == true"
                          @click="counter.active = !counter.active"
                          class="
                            absolute
                            -ml-24
                            bg-white
                            flex flex-col
                            p-4
                            shadow-xs
                            rounded
                          "
                        >
                          <button
                            class="focus:outline-none flex items-center mb-1"
                          >
                            <Iconseen
                              @click="
                                seeMeterBills(
                                  counter.cNumb,
                                  counter.createdDate
                                )
                              "
                              class="mr-3"
                            /><span>{{ $t("meterDash.see") }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            @click="editMeter(counter.cNumb, counter.id)"
                          >
                            <Iconedit class="mr-3" /><span>{{
                              $t("meterDash.edit")
                            }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            @click="passTrashCounter(counter.id)"
                          >
                            <Icontrash class="mr-3" /><span>{{
                              $t("meterDash.trash")
                            }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            @click="reload(counter.cNumb, counter.cUser)"
                          >
                            <Iconreload class="mr-3" /><span>{{
                              $t("meterDash.recharge")
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="bg-white rounded h-statedt">
              <div class="mx-auto" style="height: 300px; width: 300px">
                <img src="@/assets/gifts/dataTableEmpty.gif" alt="" />
              </div>
              <div class="mt-16 text-xl text-center">
                {{ $t("meterDash.datatableEmpty") }}
              </div>
            </div>
            <div
              v-if="searchInput && searchCounters.length == 0"
              class="bg-white rounded h-statedt"
            >
              <div class="mx-auto" style="height: 300px; width: 300px">
                <img src="@/assets/gifts/dataTableEmpty.gif" alt="" />
              </div>
              <div class="mt-16 text-xl text-center">
                {{ $t("meterDash.datatableSearchEmpty") }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:post>
          <div class="mt-4">
            <div
              class="
                mb-6
                flex
                lg:flex-row
                flex-col
                justify-between
                font-semibold
              "
            >
              <span class="text-tex-1 text-xl">
                {{ $t("meterDash.title", { count: postCounters.length }) }}
              </span>
              <span
                class="
                  lg:hidden
                  h-search
                  flex
                  mb-4
                  items-center
                  border
                  py-1
                  border-kborder-900
                  bg-kborder-300 bg-opacity-50
                  rounded
                "
              >
                <span class="px-3"><Iconsearch /></span>
                <input
                  :placeholder="$t('meterDash.search')"
                  class="w-full text-sm bg-transparent focus:outline-none"
                  type="text"
                  id="myInputlg"
                  v-model="searchPostInput"
                  @input="searchPostFunction"
                />
              </span>
              <button
                class="
                  text-white text-sm
                  bg-radio-100
                  hover:bg-compteur-600
                  rounded
                  px-10
                  py-2
                  focus:outline-none
                "
                @click="passAddCounter"
              >
                {{ $t("meterDash.addBtn") }}
              </button>
              <button
                @click="deletePostMass"
                v-show="deletePostCheck.length > 0"
                class="
                  flex
                  items-center
                  justify-center
                  lg:hidden
                  mt-3
                  text-white text-sm
                  bg-kinput-4
                  hover:bg-tex-2
                  rounded
                  px-10
                  py-2
                  focus:outline-none
                "
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7342 3.375H11.8125V1.6875C11.8125 1.53832 11.7532 1.39524 11.6477 1.28975C11.5423 1.18426 11.3992 1.125 11.25 1.125H6.75C6.60082 1.125 6.45774 1.18426 6.35225 1.28975C6.24676 1.39524 6.1875 1.53832 6.1875 1.6875V3.375H2.26582L2.25 4.78125H3.41016L4.11645 15.8203C4.13432 16.1058 4.26026 16.3737 4.46866 16.5696C4.67705 16.7656 4.95225 16.8748 5.23828 16.875H12.7617C13.0476 16.875 13.3227 16.7661 13.5312 16.5705C13.7397 16.375 13.8659 16.1074 13.8843 15.8221L14.5898 4.78125H15.75L15.7342 3.375ZM6.1875 14.625L5.87109 5.625H7.03125L7.34766 14.625H6.1875ZM9.5625 14.625H8.4375V5.625H9.5625V14.625ZM10.4062 3.375H7.59375V2.39063C7.59375 2.35333 7.60857 2.31756 7.63494 2.29119C7.66131 2.26482 7.69708 2.25 7.73438 2.25H10.2656C10.3029 2.25 10.3387 2.26482 10.3651 2.29119C10.3914 2.31756 10.4062 2.35333 10.4062 2.39063V3.375ZM11.8125 14.625H10.6523L10.9688 5.625H12.1289L11.8125 14.625Z"
                    fill="white"
                  />
                </svg>
                <span class="ml-2"
                  >{{ $t("meterDash.trash") }} ({{
                    deletePostCheck.length
                  }})</span
                >
              </button>
            </div>
            <div class="hidden lg:flex items-center mb-4">
              <button
                @click="deletePostMass"
                v-show="deletePostCheck.length > 0"
                class="
                  mr-4
                  flex
                  items-center
                  justify-center
                  text-white text-sm
                  bg-kinput-4
                  hover:bg-tex-2
                  rounded
                  px-4
                  h-search
                  focus:outline-none
                "
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7342 3.375H11.8125V1.6875C11.8125 1.53832 11.7532 1.39524 11.6477 1.28975C11.5423 1.18426 11.3992 1.125 11.25 1.125H6.75C6.60082 1.125 6.45774 1.18426 6.35225 1.28975C6.24676 1.39524 6.1875 1.53832 6.1875 1.6875V3.375H2.26582L2.25 4.78125H3.41016L4.11645 15.8203C4.13432 16.1058 4.26026 16.3737 4.46866 16.5696C4.67705 16.7656 4.95225 16.8748 5.23828 16.875H12.7617C13.0476 16.875 13.3227 16.7661 13.5312 16.5705C13.7397 16.375 13.8659 16.1074 13.8843 15.8221L14.5898 4.78125H15.75L15.7342 3.375ZM6.1875 14.625L5.87109 5.625H7.03125L7.34766 14.625H6.1875ZM9.5625 14.625H8.4375V5.625H9.5625V14.625ZM10.4062 3.375H7.59375V2.39063C7.59375 2.35333 7.60857 2.31756 7.63494 2.29119C7.66131 2.26482 7.69708 2.25 7.73438 2.25H10.2656C10.3029 2.25 10.3387 2.26482 10.3651 2.29119C10.3914 2.31756 10.4062 2.35333 10.4062 2.39063V3.375ZM11.8125 14.625H10.6523L10.9688 5.625H12.1289L11.8125 14.625Z"
                    fill="white"
                  />
                </svg>
                <span class="ml-2"
                  >{{ $t("meterDash.trash") }} ({{
                    deletePostCheck.length
                  }})</span
                >
              </button>
              <div
                class="
                  hidden
                  lg:flex
                  w-1/4
                  items-center
                  border-1.5
                  h-search
                  py-1
                  border-kborder-900
                  bg-kborder-300 bg-opacity-50
                  rounded
                "
              >
                <span class="p-3"><Iconsearch /></span>
                <input
                  :placeholder="$t('meterDash.search')"
                  class="w-full text-sm bg-transparent focus:outline-none"
                  type="text"
                  id="myInput"
                  v-model="searchPostInput"
                  @input="searchPostFunction"
                />
              </div>
            </div>
            <div v-if="postCounters.length != 0" class="bg-white">
              <table
                class="
                  w-full
                  table-auto
                  xl:table-fixed
                  text-sm
                  border border-solid
                "
              >
                <thead
                  class="
                    hidden
                    lg:table-header-group
                    font-bold
                    text-left
                    border border-bgk-2 border-solid
                  "
                >
                  <th class="py-3 px-5 w-16">
                    <input
                      type="checkbox"
                      class="form-checkbox h-5 w-5 text-radio-100"
                      id="all"
                      value="allcounters"
                      v-model="allPostDelete"
                      @click="checkPostAll"
                    />
                  </th>
                  <th>{{ $t("meterDash.subscriberNumb") }}</th>
                  <th>{{ $t("meterDash.owner") }}</th>
                  <th>{{ $t("meterDash.family") }}</th>
                  <th>{{ $t("homeDash.action") }}</th>
                </thead>
                <tbody v-if="!searchPostInput">
                  <tr
                    class="hover:bg-bgk-1 border border-bgk-2 border-solid"
                    v-for="counter in postCounters"
                    :key="counter._id"
                  >
                    <td class="py-3 px-5 w-16">
                      <input
                        type="checkbox"
                        class="form-checkbox h-5 w-5 text-radio-100"
                        :id="counter._id"
                        :value="counter._id"
                        v-model="deletePostCheck"
                        @change="updatePostCheckall"
                      />
                    </td>
                    <td class="flex flex-col p-3">
                      <span class="text-sm">{{ counter.reference }}</span>
                      <span class="text-xs lg:hidden" style="color: #4d4c4c">{{
                        counter.name
                      }}</span>
                    </td>
                    <td>
                      <span class="hidden lg:table-cell">{{
                        counter.name
                      }}</span>
                    </td>
                    <td class="flex flex-col p-3">
                      <span class="text-sm lg:hidden">{{
                        $t("meterDash.family")
                      }}</span>
                      <span class="text-xs text-tex-3 lg:text-black">{{
                        getCommonName(counter)
                      }}</span>
                    </td>
                    <td class="px-2 lg:px-0 w-8 lg:w-auto">
                      <div class="lg:flex hidden items-center">
                        <!-- <button
                          class="focus:outline-none mr-2"
                          :title="$t('tooltip.seebill')"
                        >
                          <Iconseen />
                        </button>
                        <button
                          class="focus:outline-none mr-2"
                          :title="$t('tooltip.editbill')"
                        >
                          <Iconedit />
                        </button> -->
                        <button
                          class="focus:outline-none mr-2"
                          :title="$t('tooltip.trashbill')"
                          @click="passTrashCounter(counter.reference, true)"
                        >
                          <Icontrash />
                        </button>
                      </div>

                      <div class="lg:hidden">
                        <Icondatatabl
                          @click="counter.active = !counter.active"
                          class=""
                        />
                        <div
                          v-show="counter.active == true"
                          @click="counter.active = !counter.active"
                          class="
                            absolute
                            -ml-24
                            bg-white
                            flex flex-col
                            p-4
                            shadow-xs
                            rounded
                          "
                        >
                          <!-- <button
                            class="focus:outline-none flex items-center mb-1"
                          >
                            <Iconseen class="mr-3" /><span>{{
                              $t("meterDash.see")
                            }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                          >
                            <Iconedit class="mr-3" /><span>{{
                              $t("meterDash.edit")
                            }}</span>
                          </button> -->
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            @click="passTrashCounter(counter.reference, true)"
                          >
                            <Icontrash class="mr-3" /><span>{{
                              $t("meterDash.trash")
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="searchPostInput">
                  <tr
                    class="hover:bg-bgk-1 border border-bgk-2 border-solid"
                    v-for="counter in searchPostCounters"
                    :key="counter._id"
                  >
                    <td class="py-3 px-5 w-16">
                      <input
                        type="checkbox"
                        class="form-checkbox h-5 w-5 text-radio-100"
                        :id="counter._id"
                        :value="counter._id"
                        v-model="deletePostCheck"
                        @change="updatePostCheckall"
                      />
                    </td>
                    <td class="flex flex-col p-3">
                      <span class="text-sm">{{ counter.reference }}</span>
                      <span class="text-xs lg:hidden" style="color: #4d4c4c">{{
                        counter.name
                      }}</span>
                    </td>
                    <td>
                      <span class="hidden lg:table-cell">{{
                        counter.name
                      }}</span>
                    </td>
                    <td class="flex flex-col p-3">
                      <span class="text-sm lg:hidden">{{
                        $t("meterDash.family")
                      }}</span>
                      <span class="text-xs text-tex-3 lg:text-black">{{
                        getCommonName(counter)
                      }}</span>
                    </td>
                    <td class="px-2 lg:px-0 w-8 lg:w-auto">
                      <div class="lg:flex hidden items-center">
                        <!-- <button class="focus:outline-none mr-2">
                          <Iconseen />
                        </button>
                        <button class="focus:outline-none mr-2">
                          <Iconedit />
                        </button> -->
                        <button class="focus:outline-none mr-2" @click="passTrashCounter(counter.reference, true)">
                          <Icontrash />
                        </button>
                      </div>

                      <div class="lg:hidden">
                        <Icondatatabl
                          @click="counter.active = !counter.active"
                          class=""
                        />
                        <div
                          v-show="counter.active == true"
                          @click="counter.active = !counter.active"
                          class="
                            absolute
                            -ml-24
                            bg-white
                            flex flex-col
                            p-4
                            shadow-xs
                            rounded
                          "
                        >
                          <!-- <button
                            class="focus:outline-none flex items-center mb-1"
                          >
                            <Iconseen class="mr-3" /><span>{{
                              $t("meterDash.see")
                            }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            @click="editMeter(counter.cNumb, counter.id)"
                          >
                            <Iconedit class="mr-3" /><span>{{
                              $t("meterDash.edit")
                            }}</span>
                          </button> -->
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            @click="passTrashCounter(counter.id)"
                          >
                            <Icontrash class="mr-3" /><span>{{
                              $t("meterDash.trash")
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="bg-white rounded h-statedt">
              <div class="mx-auto" style="height: 300px; width: 300px">
                <img src="@/assets/gifts/dataTableEmpty.gif" alt="" />
              </div>
              <div class="mt-16 text-xl text-center">
                {{ $t("meterDash.datatableEmpty") }}
              </div>
            </div>
            <div
              v-if="searchPostInput && searchPostCounters.length == 0"
              class="bg-white rounded h-statedt"
            >
              <div class="mx-auto" style="height: 300px; width: 300px">
                <img src="@/assets/gifts/dataTableEmpty.gif" alt="" />
              </div>
              <div class="mt-16 text-xl text-center">
                {{ $t("meterDash.datatableSearchEmpty") }}
              </div>
            </div>
          </div>
        </template>
      </tabs>
    </div>
    <KdAddCounterModal />
    <KdEditCounterModal />
    <KdTrashCounter :isPost="isPost" @confirm="deletePostCounter" />
  </div>
</template>

<script>
import KdAddCounterModal from "./kdAddCounterModal";
import KdEditCounterModal from "./kdEditCounterModal";
import KdTrashCounter from "./KdTrashCounter";
import Icontrash from "../../assets/svg/dashbIcons/trash.svg?inline";
import Iconseen from "../../assets/svg/dashbIcons/eyegreen.svg?inline";
import Iconedit from "../../assets/svg/dashbIcons/edit.svg?inline";
import Iconsearch from "../../assets/svg/dashbIcons/search.svg?inline";
import Iconreload from "../../assets/svg/dashbIcons/battery.svg?inline";
import Icondatatabl from "../../assets/svg/dashbIcons/datatablmb.svg?inline";
import Tabs from "../../components/Tabs.vue";
import axios from "axios";
export default {
  components: {
    Icontrash,
    Iconseen,
    Iconedit,
    Iconsearch,
    Iconreload,
    KdAddCounterModal,
    KdEditCounterModal,
    KdTrashCounter,
    Icondatatabl,
    Tabs,
  },
  data() {
    return {
      isPost: false,
      currentPost: null,
      deletecheck: [],
      deletePostCheck: [],
      alldelete: false,
      allPostDelete: false,
      searchInput: null,
      searchPostInput: null,
      postCounterCopie: [],
    };
  },
  watch: {
    /* deletecheck(){
            console.log(this.deletecheck)
            if (this.counters.length != this.deletecheck.length) {
               this.alldelete = []
            }
            //this.alldelete = []
        },
        alldelete(){
            if(this.alldelete.length !=0) {
                this.deletecheck = []
                this.counters.forEach(counter => {
                    this.deletecheck.push(counter.id)
                });
            }
            else {
                this.deletecheck = []
            }
        } */
  },
  computed: {
    counters() {
      return this.$store.getters.getCounter;
    },
    postCounters() {
      return this.$store.getters.getPostpaidCounters;
    },
    searchCounters() {
      return this.$store.getters.getSearchCounter;
    },
    searchPostCounters() {
      return this.$store.getters.getSearchPostCounter;
    },
  },
  methods: {
    deletePostCounter() {
      if (Array.isArray(this.currentPost)) {
        const p = [];
        for (const el of this.currentPost) {
          p.push(
            axios.patch(
              process.env.VUE_APP_API_BASE_URL + "/subscribers/unbind/" + el,
              {},
              {
                headers: {
                  Authorization:
                    "Bearer " + this.$store.getters.getAuthUser.token,
                },
              }
            )
          );
        }

        Promise.all(p).then(() => {
          this.isPost = false;
          this.currentPost = null;
          this.$store.commit("mutPostpaidCounter");
        });
      }
      axios
        .patch(
          process.env.VUE_APP_API_BASE_URL +
            "/subscribers/unbind/" +
            this.currentPost,
          {},
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getAuthUser.token,
            },
          }
        )
        .then(() => {
          this.isPost = false;
          this.currentPost = null;
          this.$store.commit("mutPostpaidCounter");
        });
    },
    getCommonName(counter) {
      const currentUserId = this.$store.getters.getAuthUser.id;
      return counter.users.find((it) => it.user === currentUserId).commonName;
    },
    reload(numb, user) {
      const meter = {
        cNumb: numb,
        cUser: user,
      };
      this.$store.commit("mutSelectedMeter", meter);
      this.$store.commit("mutCheckArrear");
      this.$router.push("/dashboard/recharge");
    },
    checkAll() {
      this.alldelete = !this.alldelete;
      this.deletecheck = [];
      if (this.alldelete) {
        // Check all
        this.counters.forEach((counter) => {
          this.deletecheck.push(counter.id);
        });
      }
    },
    checkPostAll() {
      this.allPostDelete = !this.allPostDelete;
      this.deletePostCheck = [];
      if (this.allPostDelete) {
        // Check all
        this.postCounters.forEach((counter) => {
          this.deletePostCheck.push(counter._id);
        });
      }
    },
    updateCheckall() {
      if (this.deletecheck.length == this.counters.length) {
        this.alldelete = true;
      } else {
        this.alldelete = false;
      }
    },
    updatePostCheckall() {
      if (this.deletePostCheck.length == this.postCounters.length) {
        this.allPostDelete = true;
      } else {
        this.allPostDelete = false;
      }
    },
    async deleteMass() {
      this.$store.commit("mutManyCountersDelete", this.deletecheck);
      this.deletecheck = [];
    },
    async deletePostMass() {
      this.isPost = true;
      this.currentPost = [...this.deletePostCheck];
      this.$store.commit("mutTrashCounter", true);
    },
    async searchFunctionlg() {
      let input = document.getElementById("myInputlg");
      let text = input.value.toUpperCase();
      await this.$store.commit("mutSearchCounter", text);
    },
    async searchFunction() {
      let input = document.getElementById("myInput");
      let text = input.value.toUpperCase();
      await this.$store.commit("mutSearchCounter", text);
    },
    async searchPostFunction() {
      let input = document.getElementById("myInput");
      let text = input.value.toUpperCase();
      this.$store.commit("mutSearchPostCounter", text);
    },
    async seeMeterBills(meterNumb, createdDate) {
      let meter = {
        numb: meterNumb,
        page: 1,
      };
      this.$store.commit("mutCounterToSeenCreatedDate", createdDate);
      await this.$store.commit("mutSeeCounterPurchases", meter);
      const self = this;
      setTimeout(function () {
        self.$router.push("/dashboard/counterseen");
      }, 1000);
    },
    passAddCounter() {
      this.$store.commit("mutAddCounter", true);
    },
    passTrashCounter(id, isPost) {
      this.isPost = isPost;
      this.currentPost = id;
      this.$store.commit("mutCounterId", id);
      this.$store.commit("mutTrashCounter", true);
    },
    editMeter(meterNumb, id) {
      this.$store.commit("mutEditCounter", true);
      const meter = {
        numb: meterNumb,
        id: id,
      };
      this.$store.commit("mutMeterToEdit", meter);
    },
  },
  mounted() {
    this.$store.commit("mutPostpaidCounter");
  },
};
</script>

<style scoped>
.bg-lg {
  background-image: url(../../assets/svg/SBEEA1.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
}

@media (min-width: 640px) {
  .bg-lg {
    background-image: url(../../assets/svg/dashbIcons/background2lg.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
  }
}
</style>